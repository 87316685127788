<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-bell-outline</v-icon>
        Notificações
      </v-card-title>
      <FeedsEnviados />
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Feed",

  data() {
    return {
    };
  },

  components: {
    FeedsEnviados: () => import("./components/FeedsEnviados.vue"),
  },

  methods: {},
};
</script>

<style></style>
